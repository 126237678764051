@tailwind base;
@tailwind components;
@tailwind utilities;

/*    This link controls the Font Sizes, 
      Heading Bottom Border, Paddings and 
      Marging of elements  
*/
@import "~github-markdown-css/github-markdown.css";

/* 
    THIS CUSTOM CSS OVERRIDES SOME DEFAULT GITHUB MARKDOWN CSS LIBRARY STYLINGS
    YOU CAN FIND IT IN public/index.html - in the head tag
*/
.markdown-body {
  background-color: transparent !important;
}
.markdown-body pre,
code {
  background-color: rgba(27, 31, 35, 1) !important;
}

.markdown-body h1,
h2,
h3,
h4,
h5,
h6,
p,
code {
  color: #fff;
}

.markdown-body h1,
h2 {
  border-bottom: 1px solid #21262d !important;
  font-family: "Bungee Inline";
}

.markdown-body img {
  background-color: transparent !important;
}
