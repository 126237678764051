html {
    height: 100%;
}

body {
    height: 100%;
    background-color: black;
}

#root {
    height: 100%;
}